import {
    siteWideDemoFormSettings,
    OEMFormSettings,
    OEMFormEUSettings,
    EVLeadFormSettings,
    gatedWhitePaperFormMCSettings,
    becomeAResellerSetting,
    salesInquiryFormMCSetting,
    latamGatedWhitePaperMCSettings,
    MCMicroSiteFormMCSettings,
    transportationPageContactFormSettings,
    PPCLandingFormSettings,
    EVSADemoFormSettings,
    connect2024PageSettings,
    quotingToolFormSettings,
    postConnect2024PageSettings,
    formIdSetting,
} from '@helpers/formHelpers/formSettings';
import loadable, { LoadableComponent } from '@loadable/component';
import { CustomSectionComponentProps } from '@models/customSection';

const SubscribeEnergyFormMCLoadable = loadable(() => import('@components/SubscribeEnergyFormMC'));
const MicroSiteFormSectionMCLoadable = loadable(() => import('@components/MicroSitesForm/MicroSiteFormSectionMC'));
const SalesInquiryFormMCLoadable = loadable(() => import('@components/SalesInquiryFormMC'));
const BecomeAResellerLoadable = loadable(() => import('@components/BecomeAReseller'));
const GatedWhitePaperFormMCLoadable = loadable(() => import('@components/GatedWhitePaperFormMC'));
const LatamGatedWhitePaperMCLoadable = loadable(() => import('@components/LatamGatedWhitePaperMC'));
const SiteWideDemoFormLoadable = loadable(() => import('@components/SiteWideDemoForm/SiteWideDemoForm'));
const OEMFormSectionLoadable = loadable(() => import('@components/OEMForm/OEMFormSection'));
const OEMFormSectionEULoadable = loadable(() => import('@components/OEMForm/OEMFormSectionEU'));
const SustainabilityBoxLoadable = loadable(() => import('@components/SectionComponents/SustainabilityBox'));
const GeneralInquiryFormLoadable = loadable(() => import('@components/GeneralInquiryForm'));
const ThreeGFormSectionLoadable = loadable(() => import('@components/3GForm/3GFormSection'));
const TransportationPageContactFormAULoadable = loadable(() => import('@components/TransportationPageContactFormAU'));
const EVFormAUNZLoadable = loadable(() => import('@components/EVLeadForm/EVFormAUNZ'));
const EVLeadFormSectionLoadable = loadable(() => import('@components/EVLeadForm/EVLeadFormSection'));
const SoftwarePackagesSectionLoadable = loadable(() => import('@components/SoftwarePackagesSection'));
const FindCarModelsLoadable = loadable(() => import('@components/SectionComponents/FindCarModels'));
const SubscribePageBannerLoadable = loadable(() => import('@components/SubscribePageBanner'));
const TransportationPageContactFormLoadable = loadable(() => import('@components/TransportationPageContactForm'));
const SampleCustomComponentLoadable = loadable(() => import('@components/SampleCustomComponent'));
const SecurityFormSectionLoadable = loadable(() => import('@components/SecurityForm/SecurityFormSection'));
const ELDFormSectionLoadable = loadable(() => import('@components/ELDForm/ELDFormSection'));
const PartnerFormSectionLoadable = loadable(() => import('@components/PartnerForm/PartnerFormSection'));
const PartnerFormSectionMCLoadable = loadable(() => import('@components/PartnerForm/PartnerFormSectionMC'));
const SubscribeFormSectionLoadable = loadable(() => import('@components/SubscribeFormSection'));
const MicroSiteFormSectionLoadable = loadable(() => import('@components/MicroSitesForm/MicroSiteFormSection'));
const SupportDocumentsLoadable = loadable(() => import('@components/SupportDocuments'));
const TruckingDemoFormLoadable = loadable(() => import('@components/TruckingDemoForm'));
const OEMCampaignFormLoadable = loadable(() => import('@components/OEMForm/OEMCampaignForm'));
const CurrentKnownIssuesLoadable = loadable(() => import('@components/CurrentKnownIssues'));
const PPCLandingFormLoadable = loadable(() => import('@components/PPCLandingForm/PPCLandingForm'));
const EVSADemoFormLoadable = loadable(() => import('@components/PPCLandingForm/EVSADemoForm'));
const PPCLandingFormEULoadable = loadable(() => import('@components/PPCLandingForm/PPCLandingFormEU'));
const FindEVCarModelsLoadable = loadable(() => import('@components/SectionComponents/FindEVCarModels'));
const CatalogueSearchLoadable = loadable(() => import('@components/CatalogueSearch/CatalogueSearch'));
const MarketplaceBoxLoadable = loadable(() => import('@components/SectionComponents/MarketplaceBox'));
const ConnectEventsScheduleLoadable = loadable(() => import('@components/ConnectEventsSchedule'));
const StackedLoginLoadable = loadable(() => import('@components/StackedLogin'));
const Connect2024PageLoadable = loadable(() => import('@components/Connect2024/Connect2024Page'));
const PostConnect2024PageLoadable = loadable(() => import('@components/Connect2024/PostConnect/PostConnect2024Page'));
const HarnessLookupLoadable = loadable(() => import('@components/SectionComponents/HarnessLookup'));
const ROICalculatorLoadable = loadable(() => import('@components/SectionComponents/ROICalculator'));
const QuotingToolLoadable = loadable(() => import('@components/QuotingTool/QuotingTool'));
const GraderLoadable = loadable(() => import('@sections/embed/grader/Grader'));
const LongpageLoadable = loadable(() => import('@sections/embed/longpage/Longpage'));
const ContactSectionLoadable = loadable(() => import('@components/SectionComponents/ContactSection'));

enum CustomSectionGroup {
    Forms = 'Forms',
    CmsComponents = 'CMS Components',
}

interface CustomSectionType {
    value: string;
    name: string;
    component:
        | LoadableComponent<CustomSectionComponentProps>
        | LoadableComponent<unknown>
        | LoadableComponent<{ lazyLoad: boolean }>;
    groups: CustomSectionGroup;
    settings?: {
        name: string;
        description: string;
    }[];
}

type CustomSectionGroups = {
    value: string;
    content: string;
    group: CustomSectionGroup;
}[];

export function getCustomSectionGroups(sectionTypes: CustomSectionType[]): CustomSectionGroups {
    return sectionTypes.map((sectionType) => {
        return {
            value: sectionType.value,
            content: sectionType.name,
            group: sectionType.groups,
        };
    });
}

export const findSectionTypeByName = (
    sectionTypes: CustomSectionType[],
    currentComponent: string
): CustomSectionType | undefined => sectionTypes.find((element) => element.value === currentComponent);

export const customSectionTypes: CustomSectionType[] = [
    {
        value: 'ELDFormSection',
        name: 'ELD Page Form',
        settings: formIdSetting,
        component: ELDFormSectionLoadable,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'PartnerFormSectionMC',
        name: 'Partner Page Form MC',
        settings: formIdSetting,
        component: PartnerFormSectionMCLoadable,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'TransportationPageContactFormSection',
        name: 'Transportation Page Contact Form',
        component: TransportationPageContactFormLoadable,
        groups: CustomSectionGroup.Forms,
        settings: transportationPageContactFormSettings,
    },
    {
        value: 'TransportationPageContactFormSectionAU',
        name: 'Transportation Page Contact Form AU SFMC',
        settings: formIdSetting,
        component: TransportationPageContactFormAULoadable,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: '3GContactFormSection',
        name: '3G Contact Form Section',
        settings: formIdSetting,
        component: ThreeGFormSectionLoadable,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'EVLeadFormSection',
        name: 'EV Lead Form',
        component: EVLeadFormSectionLoadable,
        settings: EVLeadFormSettings,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'EVFormAUNZ',
        name: 'EV Form AU-NZ',
        settings: formIdSetting,
        component: EVFormAUNZLoadable,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'GatedWhitePaperFormMC',
        name: 'Gated White Paper Form MC',
        component: GatedWhitePaperFormMCLoadable,
        settings: gatedWhitePaperFormMCSettings,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'LatamGatedWhitePaperMC',
        name: 'Latam Gated White Paper MC',
        component: LatamGatedWhitePaperMCLoadable,
        settings: latamGatedWhitePaperMCSettings,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'MultistepDemoForm',
        name: 'Site-wide Demo Form',
        component: SiteWideDemoFormLoadable,
        settings: siteWideDemoFormSettings,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'OEMFormSection',
        name: 'OEM Form (MC)',
        component: OEMFormSectionLoadable,
        settings: OEMFormSettings,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'OEMFormSectionEU',
        name: 'OEM Form EU (MC)',
        component: OEMFormSectionEULoadable,
        settings: OEMFormEUSettings,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'Micro-Site Form Section',
        name: 'Micro-Site Form Section',
        settings: formIdSetting,
        component: MicroSiteFormSectionLoadable,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'MCMicroSiteForm',
        name: 'Marketing Cloud Micro-Site Form',
        component: MicroSiteFormSectionMCLoadable,
        settings: MCMicroSiteFormMCSettings,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'SecurityFormSection',
        name: 'Security Page Form',
        settings: formIdSetting,
        component: SecurityFormSectionLoadable,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'PartnerFormSection',
        name: 'Partner Page Form',
        settings: formIdSetting,
        component: PartnerFormSectionLoadable,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'SubscribeFormSection',
        name: 'Subscribe Form Section',
        settings: formIdSetting,
        component: SubscribeFormSectionLoadable,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'SubscribePageBannerSection',
        name: 'Subscribe Page Banner Section',
        component: SubscribePageBannerLoadable,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'SubscribeEngeryFormMC',
        name: 'Subscribe Energy Form Marketing Cloud',
        component: SubscribeEnergyFormMCLoadable,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'SampleCustomComponent',
        name: 'Sample Custom Section',
        component: SampleCustomComponentLoadable,
        groups: CustomSectionGroup.CmsComponents,
    },
    {
        value: 'SupportDocuments',
        name: 'Support Documents',
        component: SupportDocumentsLoadable,
        groups: CustomSectionGroup.CmsComponents,
    },
    {
        value: 'SustainabilityBox',
        name: 'Sustainability Box',
        component: SustainabilityBoxLoadable,
        groups: CustomSectionGroup.CmsComponents,
    },
    {
        value: 'SoftwarePackagesSection',
        name: 'Software Packages Section',
        component: SoftwarePackagesSectionLoadable,
        groups: CustomSectionGroup.CmsComponents,
    },
    {
        value: 'FindCarModelsSection',
        name: 'Find Car Models Section',
        component: FindCarModelsLoadable,
        groups: CustomSectionGroup.CmsComponents,
    },
    {
        value: 'MarketplaceBox',
        name: 'Marketplace Box',
        component: MarketplaceBoxLoadable,
        groups: CustomSectionGroup.CmsComponents,
    },
    {
        value: 'MCSalesInquiryFormGlobal',
        name: 'Marketing Cloud Sales Inquiry Form Global',
        component: SalesInquiryFormMCLoadable,
        settings: salesInquiryFormMCSetting,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'BecomeAResellerForm',
        name: 'Become A Reseller Form',
        component: BecomeAResellerLoadable,
        settings: becomeAResellerSetting,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'GeneralInquiryForm',
        name: 'General Inquiry Form',
        settings: formIdSetting,
        component: GeneralInquiryFormLoadable,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'TruckingDemoForm',
        name: 'Trucking Demo Form',
        settings: formIdSetting,
        component: TruckingDemoFormLoadable,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'OEMCampaignForm',
        name: 'OEM Campaign Form',
        settings: formIdSetting,
        component: OEMCampaignFormLoadable,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'CurrentKnownIssues',
        name: 'Current Known Issues',
        component: CurrentKnownIssuesLoadable,
        groups: CustomSectionGroup.CmsComponents,
    },
    {
        value: 'PPCLandingForm',
        name: 'PPC Landing Page Form',
        component: PPCLandingFormLoadable,
        settings: PPCLandingFormSettings,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'EVSADemoForm',
        name: 'EVSA Demo Form',
        component: EVSADemoFormLoadable,
        settings: EVSADemoFormSettings,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'PPCLandingFormEU',
        name: 'PPC Landing Page Form EU',
        component: PPCLandingFormEULoadable,
        settings: PPCLandingFormSettings,
        groups: CustomSectionGroup.Forms,
    },
    {
        value: 'FindEVCarModelsSection',
        name: 'Find EV Car Models Section',
        component: FindEVCarModelsLoadable,
        groups: CustomSectionGroup.CmsComponents,
    },
    {
        value: 'KeyboxCatalogSearch',
        name: 'Keybox Catalog',
        component: CatalogueSearchLoadable,
        groups: CustomSectionGroup.CmsComponents,
    },
    {
        value: 'ConnectEeventsSchedule',
        name: 'Connect Events Schedule',
        component: ConnectEventsScheduleLoadable,
        groups: CustomSectionGroup.CmsComponents,
    },
    {
        value: 'StackedLogin',
        name: 'Stacked Login',
        component: StackedLoginLoadable,
        groups: CustomSectionGroup.CmsComponents,
    },
    {
        value: 'Connect2024Page',
        name: 'Connect 2024 Page',
        component: Connect2024PageLoadable,
        groups: CustomSectionGroup.CmsComponents,
        settings: connect2024PageSettings,
    },
    {
        value: 'PostConnect2024Page',
        name: 'Post Connect 2024 Page',
        component: PostConnect2024PageLoadable,
        groups: CustomSectionGroup.CmsComponents,
        settings: postConnect2024PageSettings,
    },
    {
        value: 'HarnessLookup',
        name: 'Harness Lookup',
        component: HarnessLookupLoadable,
        groups: CustomSectionGroup.CmsComponents,
    },
    {
        value: 'ROICalculator',
        name: 'ROI Calculator',
        component: ROICalculatorLoadable,
        groups: CustomSectionGroup.CmsComponents,
    },
    {
        value: 'QuotingTool',
        name: 'Quoting Tool',
        component: QuotingToolLoadable,
        groups: CustomSectionGroup.CmsComponents,
        settings: quotingToolFormSettings,
    },
    {
        value: 'Grader',
        name: 'Grader',
        component: GraderLoadable,
        groups: CustomSectionGroup.CmsComponents,
    },
    {
        value: 'Longpage',
        name: 'Longpage',
        component: LongpageLoadable,
        groups: CustomSectionGroup.CmsComponents,
    },
    {
        value: 'ContactSection',
        name: 'Contact Section',
        component: ContactSectionLoadable,
        groups: CustomSectionGroup.CmsComponents,
    },
];

export const validCustomSectionValues = customSectionTypes.map((section) => section.value || section.name);

export const customSectionForms = customSectionTypes.filter((section) => section.groups === CustomSectionGroup.Forms);
